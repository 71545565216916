@import '../../style/var';
.analyze_body {
	background: url($https_url + 'result_bg2.png') center no-repeat;
	min-width: 1100px;
	height: 941px;
	// position: relative;
}

.analyze_container {
	padding-top: 200px;
}

.hidden {
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
	opacity: 0;
}

.analyze_tabs {
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	width: 100%;
	font-weight: 300;
	font-size: 2em;
	// margin: 33% auto;
}

/* Nav */
.analyze_tabs nav {
	text-align: center;
}

.analyze_tabs nav ul {
	position: relative;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
	margin: 0 auto;
	padding: 0;
	max-width: 640px;
	list-style: none;
	-ms-box-orient: horizontal;
	-ms-box-pack: center;
	-webkit-flex-flow: row wrap;
	-moz-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
}

.analyze_tabs nav ul li {
	position: relative;
	z-index: 1;
	display: block;
	margin: 0;
	text-align: center;
	-webkit-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.analyze_tabs nav a {
	position: relative;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 2.5;
}

.analyze_tabs nav a span {
	vertical-align: middle;
	font-size: 0.75em;
}

.analyze_tabs nav li.tab-current a {
	color: #74777b;
}

.analyze_tabs nav a:focus {
	outline: none;
}

.analyze_tabs-style-shape {
	max-width: 640px;
}

.analyze_tabs-style-shape nav ul li {
	margin: 0 3em;
}

.analyze_tabs-style-shape nav ul li:first-child {
	margin-left: 0;
}

.analyze_tabs-style-shape nav ul li.tab-current {
	z-index: 100;
}

.analyze_tabs-style-shape nav li a {
	overflow: visible;
	margin: 0 -3em 0 0;
	padding: 0;
	color: #fff;
	font-weight: 500;
}

.analyze_tabs-style-shape nav li:first-child a span {
	padding-left: 2em;
	border-radius: 30px 0 0 0;
}

.analyze_tabs-style-shape nav li:last-child a span {
	padding-right: 2em;
	border-radius: 0 30px 0 0;
}

.analyze_tabs-style-shape nav li a svg {
	position: absolute;
	left: 100%;
	margin: 0;
	width: 3em;
	height: 100%;
	fill: #bdc2c9;
}

.analyze_tabs-style-shape nav li a svg:nth-child(2),
.analyze_tabs-style-shape nav li:last-child a svg {
	right: 100%;
	left: auto;
	-webkit-transform: scale3d(-1.2, 1, 1);
	transform: scale3d(-1.2, 1, 1);
}

.analyze_tabs-style-shape nav li a span {
	display: block;
	overflow: hidden;
	padding: 0.65em 0;
	background-color: #bdc2c9;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.analyze_tabs-style-shape nav li a:hover span {
	background-color: $theme;
}

.analyze_tabs-style-shape nav li a:hover svg {
	fill: $theme;
}

/* Make only shape clickable */
.analyze_tabs-style-shape nav li a svg {
	pointer-events: none;
}

.analyze_tabs-style-shape nav li a svg use {
	pointer-events: auto;
}

.analyze_tabs-style-shape nav li.tab-current a span,
.analyze_tabs-style-shape nav li.tab-current a svg {
	-webkit-transition: none;
	transition: none;
}

.analyze_tabs-style-shape nav li.tab-current a span {
	background: #fff;
}

.analyze_tabs-style-shape nav li.tab-current a svg {
	fill: #fff;
}

.analyze_tabs-style-shape .content-wrap {
	background: #fff;
}

@media screen and (max-width: 58em) {
	.analyze_tabs-style-shape nav ul {
		display: block;
		padding-top: 1.5em;
	}

	.analyze_tabs-style-shape nav ul li {
		display: block;
		margin: -1.25em 0 0;
		-webkit-flex: none;
		flex: none;
	}

	.analyze_tabs-style-shape nav ul li a {
		margin: 0;
	}

	.analyze_tabs-style-shape nav ul li svg {
		display: none;
	}

	.analyze_tabs-style-shape nav ul li a span {
		padding: 1.25em 0 2em !important;
		border-radius: 30px 30px 0 0 !important;
		box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);
		line-height: 1;
	}

	.analyze_tabs-style-shape nav ul li:last-child a span {
		padding: 1.25em 0 !important;
	}

	.analyze_tabs-style-shape nav ul li.tab-current {
		z-index: 1;
	}
}

.tabs-style-shape nav li.tab-current a span,
.tabs-style-shape nav li.tab-current a svg {
	-webkit-transition: none;
	transition: none;
}

.tabs-style-shape nav li.tab-current a span {
	background: #fff;
}

.tabs-style-shape nav li.tab-current a svg {
	fill: #fff;
}

.analyze_content-wrap {
	width: 640px;
	height: 400px;
	background: #fff;
	border-radius: $border-radius;
	margin-top: -4px;
	padding-top: 50px;
	font-size: 16px;
	&-img {
		width: 280px;
		height: 200px;
		margin-left: 180px;
	}

	p {
		text-align: center;
	}
}

.analyze_active-tabs {
	z-index: 100;
	background: $theme;
	fill: $theme;
}

.analyze_content-btn {
	margin-left: 42%;
	margin-top: 30px;
	margin-bottom: 5%;
}

// 修改 antd disabled 状态样式
.ant-btn-primary[disabled] {
	color: #CED3E1;
	border: none;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover {
	border: none;
}

.analyze_content-btn2 {
	margin-left: 42%;
	margin-top: 20px;
	margin-bottom: 10px;
	width: 110px;
	height: 38px;
	background: #4DC3FF;
	opacity: 1;
	border-radius: 6px;
	border: 1px solid #4DC3FF;
}

.analyze_content-count {
	position: absolute;
	right: 70px;
	bottom: 73px;
	color: $sec-color;
	font-size: 16px;
}

.analyze_content-input {
	width: 80%;
	margin-left: 70px;
	border: 3px dashed #CED3E1;
}

.analyze_active-action {
	display: block;
}

// 修改蚂蚁样式
.ant-upload.ant-upload-drag {
	width: 68%;
	margin: auto;
	height: 220px;
}



.ant-input:hover {
	color: #74777b;
	border-right-width: 3px !important
}

.ant-upload-list {
	margin: 0 100px;
}