@import '../../../../style/var';
.body {
    height: 500px;
    width: 100%;
    background: url('https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/banner.png') center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    &__content {
        width: 1100px;
        color: $white-color;
        @include flex2Col;
        margin: auto;
        &-title {
            margin-bottom: $m-b-30;
            font-size: $font-size-0;
            font-weight: $font-weight-1;
        }
        &-des {
            margin-bottom: $m-b-30;
            font-size: $font-size-7;
        }
        &-btn {
            @include flex2Row;
            width: 250px;
            &-left {
                @include ucpinBtn;
                background-color: $theme;
                color: $white-color;
                margin-right: 20px;
                :focus {
                    outline: none
                }
            }
            &-right {
                @include ucpinBtn;
                background-color: $warning-light-color;
                color: $white-color;
                :focus {
                    outline: none
                }
            }
        }
    }
}

.body__content-btn-right:hover {
    background: #FFAA00;
}

.body__content-btn-left:hover {
    background: #0DA7F6;
}
