
// build 2020.08.21 to set common vars by Iris;
// Colors
$https_url: 'https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/';
$theme: #4dc3ff; // 主题
$secondary-bg: #dbf3ff; // 主题辅助
$error-bg: #ffedf1; // 错误状态
$warning-bg: #fffae5; // 警告状态
$page-bg: #f2f4fa; // 页面底色
$main-color: #323232; // 主要文本
$sec-color: #868fa6; // 次要文本
$placehoder-color: #ced3e1; // 占位符
$white-color: #fff; // 白色
$error-color: #ff537a; // 错误
$warning-color: #ffaa00; // 警告
$warning-light-color: #ffcc00; // 工资
$gray_bg: #f9fbff;

$width: 1100px;
$header_height: 70px;
$m-b-30: 30px;

// Typography
$font-size-0: 60px;
$font-size-1: 40px;
$font-size-2: 32px;
$font-size-3: 30px;
$font-size-4: 26px;
$font-size-5: 24px;
$font-size-6: 22px;
$font-size-7: 20px;
$font-size-8: 16px;

$font-weight-0: normal;
$font-weight-1: bold;

// Typesetting
$p-lr: 0 20px; // 排版的左右外边距
$p-tb: 10px 0; // 排版的上下外边距
$p-l: 0 20px 0 0;
$p-r: 0 0 0 20px;
$m-all: 30px; // 排版的内边距
$m-lr: 0 30px; // 排版的左右内边距
$m-b: 30px; // 排版的底部边距

$border-radius: 6px; // 圆角

@mixin logoIcon {
    width: 100px;
    height: 34px;
}

@mixin flex2Row {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
}

@mixin flex2Col {
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
}

@mixin ucpinBtn {
    border: none;
    border-radius: $border-radius;
    width: 110px;
    height: 38px;
    padding: 0;
    cursor: pointer;
}

@mixin textOverSingle {
    text-overflow:ellipsis;
    white-space:nowrap;
}
