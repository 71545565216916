@import 'style/var';
// @import 'style/var';
// @import 'style/common';
// @import '~antd/dist/antd.css';
// @import './style/var';
* {
    padding: 0;
    margin: 0;
}
.App {
    font-size: 14px;
    // width: 100%;
    min-width: 1100px;
}

a{
    text-decoration: none;
    color: black;
}


ul,li{ padding:0;margin:0;list-style:none}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

// 清除input选中的边框样式
// input[type=text]:focus{ outline: none;}
// input[type=number]:focus{ outline: none;}
// input[type=tel]:focus{ outline: none;}