@import '../../../../style/var';
.card {
    width: $width;
    height: 400px;
    @include flex2Row;
    margin: 0 auto;
    &__img {
        width: 600px;
    }
    &__word {
        @include flex2Col;
        justify-content: center;
        &--title {
            font-size: $font-size-2;
            font-weight: $font-weight-1;
            margin-bottom: $m-b-30;
        }
        &--des {
            width: 340px;
            color: $sec-color;
            line-height: 30px;
            word-wrap: break-word;
            word-break: normal;
        }
    }
}

.gray {
    background-color: $gray_bg;
}