@import '../../style/var';
.ucpin_web_footer {
    @include flex2Row;
    width: $width;
    height: 70px;
    align-items: center;
    margin: auto;
    justify-content: space-between;
    &--concact {
        @include flex2Row;
        align-items: center;
        &-t {
            padding-left: 20px;
        }
    }
    &--record {
        @include flex2Row;
        align-items: center;
        color: $sec-color;
    }
}