@import '../../../style/var';
.analyze_result_body {
    background: url($https_url + 'result_bg2.png') top no-repeat;
    min-width: 1100px;
    min-height: 941px;
    padding-bottom: 50px;
    background-color: #f2f4fa;
    width: 100%;
    height: 100%;
    zoom: 1;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}

.analyze_result_margin {
    padding-bottom: 50px;
}

.analyze_result {
    width: 1100px;
    background: #fff;
    margin: 0 auto;
    border-radius: $border-radius;
    padding: 40px;

    &__btns {
        @include flex2Row;
        justify-content: space-between;
        color: $sec-color;
        img {
            margin-right: 10px;
            margin-top: -3px;
        }
        button {
            background: #fff;
        }
    }

    &__nav {
        @include flex2Row;
        justify-content: space-between;
        width: 140px;
        margin-bottom: 15px;
        button {
            :hover {
                border: none;
                cursor: pointer;
            }

            :focus {
                border: 0 none;
                outline: none;
            }
            width: 80px;
            height: 30px;
            border-radius: 2px;
            
        }

        &-no {
            background: #fff;
            color: $sec-color;
        }

        &-active {
            background: #F9FBFF;
            color: $theme;
        }
    }

    &__top {
        background-color: #F9FBFF;
        padding: 26px 40px;
        border-radius: $border-radius;
        color: #323232;
        width: 1100px;
        margin-left: -40px;
        &-title {
            font-size: $font-size-5;
            font-weight: $font-weight-1;
        }

        &-des {
            @include flex2Row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;

            &-d {
                margin: 0 20px;
                color: $sec-color;
            }
        }
    }

    &__hr {
        padding: 20px 0;
        @include flex2Col;

        &-info {
            padding-bottom: 10px;
            border-bottom: 1px solid $page-bg;

            img {
                vertical-align: middle;
                margin-right: 10px;
            }

            span {
                vertical-align: middle;
                color: $font-size-8;
            }
        }

        &-line {
            width: 40px;
            height: 4px;
            background: $theme;
            margin-top: -2px;
        }
    }

    &__content {
        @include flex2Row;
        flex-wrap: wrap;
        margin-bottom: 20px;

        &-row {
            width: 90%;
            @include flex2Row;
            flex-wrap: wrap;
            margin-bottom: 5px;

            &-name {
                width: 80px;
                color: $sec-color;
            }
        }

        &-item {
            width: 50%;
            @include flex2Row;
            margin-bottom: 5px;

            &-name {
                width: 80px;
                color: $sec-color;
            }
        }
    }
}