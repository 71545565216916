@import '../../style/var';
.ucpin_header {
    @include flex2Row;
    width: $width;
    height: $header_height;
    align-items: center;
    margin: 0 auto;
    &__logo {
        @include logoIcon;
    }
    &__nav {
        list-style: none;
        display: flex;
        margin: 0;
        &-item {
            margin-left: 30px;
            font-weight: bold;
            width: 80px;
            height: 30px;
            text-align: center;
            cursor: pointer;
            line-height: 30px;
            display: block;
            // background-color: $secondary-bg;
            :hover {
                display: block;
                background-color: $secondary-bg;
                color: $theme;
                border-radius: 2px;
            }
        }
    }

    .login_nav {
        margin-left: 100px;
        a {
            font-weight: normal;
            font-size: 14px;
            color: $main-color;
        }
    }

    .ucpin_ai-nav {
        background-color: #fff;
        background-image: url("https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/down.png");
        background-position-x: right;
        background-position-y: 50%;
        background-repeat:no-repeat;
    }
    .ucpin_ai-nav-active {
        background-color: #fff;
        background-image: url("https://ucpin.oss-cn-shenzhen.aliyuncs.com/AI-static/up.png");
        background-position-x: right;
        background-position-y: 15%;
        background-repeat:no-repeat;
    }

    .ucpin_select {
        width: 100px;
        margin-top: 20px;
        box-shadow: 0 0 1px #ccc;
        background-color: #fff;
        transition: all 0.35s;
        &__item {
            height: 40px;
            @include flex2Col;
            text-align: left;
            padding-left: 20px;
            line-height: 40px;
            color: black;
            :hover {
                background-color: #fff;
                color: $theme;
                height:250px;
            }
        }
    }

}
