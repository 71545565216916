@import '../../../../style/var';
.model_shadow {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // 黑色背景,透明度
    opacity: 0.4;
    background-color: #000;
    // 层级位于弹出层父级之下
    // z-index: 10000;
    display: flex;
    align-items: center;
}
.apply_model {
    @include flex2Col;
    // width: 360px;
    padding: 40px 60px;
    background-color: #fff;
    position: fixed;
    top: 15%;
    left: 35%;
    text-align: center;
    align-items: center;
    border-radius: $border-radius;
    &__closeBtn {
        position: absolute;
        right: 20px;
        top: 20px;
    }
    &__title {
        font-size: $font-size-3;
        font-weight: $font-weight-1;
        color: #323232;
    }
    &__des {
        font-size: $font-size-8;
        color: $sec-color;
    }
    &__confirm {
        width: 160px;
        height: 38px;
        background-color: $theme;
        color: $white-color;
        line-height: 38px;
        text-align: center;
        border-radius: $border-radius;
        margin-top: 10px;
    }
}
// 更改antd样式
.ant-form-item {
    margin-bottom: 0;
}

.ant-form-item-control-input-content {
    width: 360px;
    margin: 10px 0;
    textarea {
        min-height: 100px;
        border: none;
        background-color: #f7f8fc;
        color: $sec-color;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #f0f0f0;
        background-color: #f7f8fc;
        input {
            line-height: 38px;
            height: 38px;
            border: none;
            background-color: #f7f8fc;
            color: $sec-color;
            :focus {
                outline: none;
            }
        }
     }
}
 .ant-form-item-explain {
    text-align: left;
    position: absolute;
    top: 20px;
    right: 8px;
 }

 .ant-input-search-button {
    height: 47px;
 }